<section class="container-blue">
    <div class="container d-flex">
        <div>
            <h2>
                Indicações
                <svg-icon class="icon-condutor" src="../../../assets/icon/condutor.svg"></svg-icon>
            </h2>
            <hr size="10" width="50%" align="left" class="linha-horizontal">
            <p>Informações da Indicação.</p>
            <p>
              <i class="bi bi-chevron-right"></i>
                <a href="">Indicações</a>
              <i class="bi bi-chevron-right"></i>
                <a href="/indicacoes-arquivadas" class="caminho-pagina">Controle de Indicações</a>
            </p>
        </div>
        <button class="btn-arquivadas" type="button" class="btn" [routerLink]="['/indicacoes-arquivadas']">
          <i class="bi bi-arrow-return-left"></i>
          Voltar
      </button>
    </div>
  </section>
  <section class="modal-lotes containers">
    <div class="header-modal d-flex">
      <h6 class="bi bi-exclamation-circle">
        Informações detalhadas da indicação
      </h6>
    </div>
    <div class="container-info d-flex" *ngIf="detalheIndicacaoSelecionado">
      <div class="info-recurso">
        <div class="d-flex line2 flex-wrap">
          <div class="p-2 col-12">
            <div *ngIf="detalheIndicacaoSelecionado.motivoArquivamento">
              <h6 class="titulos-campos">MOTIVO ARQUIVAMENTO</h6>
              <p id="mtvArquivamento">{{ detalheIndicacaoSelecionado.motivoArquivamento }}</p>
            </div>
            <div *ngIf="!detalheIndicacaoSelecionado.motivoArquivamento">
              <h6 class="titulos-campos">INDICAÇÃO VALIDADA</h6>
            </div>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">AUTO DE INFRAÇÃO</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.autoInfracao}}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">PLACA</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.placa }}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">PROTOCOLO</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.nrProtocolo }}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">DATA INDICAÇÃO</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.dataProtocolo| date : 'dd/MM/YYYY'  }}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">NOME CONDUTOR</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.nomeIndicado }}</p>
          </div>
          <div class="p-2 col-6" style="padding: 0.1rem !important">
            <h6 class="titulos-campos">CNH/UF</h6>
              <p class="conteudo-campos">
              {{ detalheIndicacaoSelecionado.habilitacao_NumeroDocumento }}/{{ detalheIndicacaoSelecionado.habilitacao_UfDocumento }}
              </p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">EMAIL</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.email }}</p>
          </div>
          <div class="p-2 col-md-6 col-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">DATA DE ARQUIVAMENTO</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.dataAnalise | date : 'd/MM/y'}}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">TELEFONE</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.telefone }}</p>
          </div>
          <div class="p-2 col-md-6 col-xs-12" style="padding:0.1rem !important">
            <h6 class="titulos-campos">USUÁRIO AVALIAÇÃO</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.userAvaliacaoNome }}</p>
          </div>
          <div class="p-2 col-6" style="padding:0.1rem !important">
            <h6 class="titulos-campos">LOTE</h6>
            <p class="conteudo-campos">{{ detalheIndicacaoSelecionado.lote}}</p>
          </div>
        </div>
        <app-multi-checkbox-file *ngIf="carregouImagens" [checkboxs]="checkboxsFile" (checkboxSelectFileEmit)="receberValueCheckboxFile($event)"></app-multi-checkbox-file>
        <div class="container-btn-editar col-12">
          <button *ngIf="!detalheIndicacaoSelecionado.dataTransmissao" type="button" class="btn-editar" (click)="openEdicaoIndicacao(detalheIndicacaoSelecionado.recursoId)">
            <i class="bi bi-pencil-square"></i>
            Editar
          </button>
        </div>
      </div>
      <div class="container-imagem">
        <div>
          <div class="p-2">
            <h6 class="conteudo-campos p-2">Indicação de Condutor</h6>
          </div>
        </div>
        <div class="imagens-recurso imagem d-flex col-6 justify-content-center">
          <div  *ngIf="mostrarImagem3" class="container-imagem2" id="primeiraImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem3"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem3
                [fullImage]=imagem3
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('primeiraImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <div  *ngIf="mostrarImagem2" class="container-imagem2" id="segundaImagem">
            <lib-ngx-image-zoom
                *ngIf="mostrarImagem2"
                [ngClass]="mostrarDoisArquivos?
                    'duas-imagem-zoom' : 'uma-imagem-zoom' "
                [thumbImage]=imagem2
                [fullImage]=imagem2
                [magnification]="1"
                [enableScrollZoom]="true"
                [enableLens]="true"
                [lensWidth]="250"
                [lensHeight]="200"
                [zoomMode]="'toggle-click'"
                [scrollStepSize]="0.1"
            ></lib-ngx-image-zoom >
            <button class="btn-rotation" (click)="RotacionarImagem('segundaImagem')"><i class="bi bi-arrow-clockwise"></i></button>
        </div>
        <app-pdf-viewer *ngIf="mostrarPdf3" [base64Pdf]="imagem3" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        <app-pdf-viewer *ngIf="mostrarPdf2" [base64Pdf]="imagem2" [exibirDuasPdfs]="mostrarDoisArquivos"></app-pdf-viewer>
        </div>
      </div>
    </div>
  </section>
