export const environment = {
  production: true,
  featureRecurso: true,
  possui: true,
  api: 'https://processosgru.homologacao.promultonline.com.br',
  urlImpressao: "http://impressaogru.homologacao.promultonline.com.br/",
  urlProcessamento: "http://processamentogru.homologacao.promultonline.com.br/Processamento/PrincipalProcessamento.aspx",
  auth: {
    issuer: 'https://identity.auth.promultonline.com.br',
    clientId: 'processosadm',
    postLogoutRedirectUri: 'https://processosgru.homologacao.promultonline.com.br',
    redirectUri: 'https://processosgru.homologacao.promultonline.com.br/signin-oidc',
    scope:"openid profile email processosadm management",
    oidc: true,
  }
};
