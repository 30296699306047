import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IndicacaoCondutor } from 'src/app/features/processo-de-validacao/models/informacao-lote';
import { IndicacaoService } from 'src/app/features/processo-de-validacao/service/indicacao.service';
import { TipoImagem } from 'src/app/shared/enums/TipoImagem';
import { Documento } from 'src/app/shared/interfaces/documento';
import { MultiCheckbox } from 'src/app/shared/interfaces/multi-checkbox';
import { Buffer} from 'buffer';
import { motivoArquivamentoService } from 'src/app/features/motivo-arquivamento/service/motivo-arquivamento.service';
import { MotivoArquivamento } from 'src/app/features/motivo-arquivamento/models/motivoArquivamento';
import { LoteService } from 'src/app/features/indicacao-condutor/services/lote.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { format } from 'path';

declare var $: any;

@Component({
  selector: 'app-edicao-indicacao',
  templateUrl: './edicao-indicacao.component.html',
  styleUrls: ['./edicao-indicacao.component.scss']
})
export class EdicaoIndicacaoComponent implements OnInit{

  @Input()
  public indicacaoCondutores:  IndicacaoCondutor[];

  @Input()
  public loteId: number;

  public imagem1: string;
  public imagem2: string;
  public imagem3: string;
  public imagem4: string;
  public positionImagem = 0;
  public mostrarImagem1: boolean;
  public mostrarImagem2: boolean;
  public mostrarImagem3: boolean;
  public mostrarImagem4: boolean;
  public mostrarPdf1: boolean;
  public mostrarPdf2: boolean;
  public mostrarPdf3: boolean;
  public mostrarPdf4: boolean;
  public mostrarDoisArquivos:boolean;

  public indicacaoCondutorSelecionado: any;
  public validators: FormGroup;
  public imagemSelect: string;
  public carregouImagens: boolean;
  public tipoImagem = TipoImagem;
  public checkDocumento: boolean = false;
  public motivosArquivamento: MotivoArquivamento[] = []
  checkboxIndicacaoValida = false;
  public textoConfirmacaoModal: string = ''; 
  estadoSelecionado: string;
  public estados: string[] = [
    "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", 
    "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", 
    "RS", "RO", "RR", "SC", "SP", "SE", "TO"
  ];

  public checkboxs: MultiCheckbox[] = [
    { name: "CNH", isCheck: false, value: 2  },
    { name: "PGU", isCheck: false, value: 1 },
    { name: "Internacional", isCheck: false, value: 3}
  ];

  public checkboxsFile: MultiCheckbox[] = []

  constructor( private formBuilder: FormBuilder,
    private indicacaoService: IndicacaoService,
    private motivoArquivamentoService: motivoArquivamentoService,
    private loteService: LoteService,
    private route: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute)
  {
    this.validators = this.formBuilder.group(
      {
        dataEntrada: ['', Validators.required],
        dataAnalise: ['', Validators.required],
        nomeCondutor: ['', Validators.required],
        telefone: [''],
        email: [''],
        numeroLote: ['', Validators.required],
        usuarioAvaliacao: ['', Validators.required],
        tipoDocumento: ['', Validators.required],
        numeroDocumento: ['', Validators.required],
        regiao: ['', [Validators.required, Validators.minLength(2)]],
        motivoArquivamento: [''],
        confirmacaoIndicacaoCondutor: ['', Validators.required],
        indicacaoAceita: [''],
        motivoArq: [0]
      },
      { updateOn: 'change' }
    );
  }
  public async  ngOnInit(): Promise<void> {
    
    var id: number = 0;
    this.activatedRoute.paramMap.subscribe(params => {
      id = +params.get('id')!;
    });
    const dadosIndicacao = await this.indicacaoService.getIndicacao(id);
    this.indicacaoCondutorSelecionado = dadosIndicacao;
    //await this.definirIndicacaoCondutorSelecionada();
    this.popularFormulario();
    await this.obterImagens();
    await this.obterMotivoArquivamento();
    this.validators.get('dataEntrada')!.disable();
    this.validators.get('dataAnalise')!.disable();
    this.validators.get('numeroLote')!.disable();
    this.validators.get('usuarioAvaliacao')!.disable();
    // this.validators.get('motivoArquivamento')!.disable();
    this.updateRegiaoValidator();
    
    //Validacao false ao carregar a pagina
    const validacaoFalse = this.validators.get('confirmacaoIndicacaoCondutor')!
    validacaoFalse.setValue(0);

    this.checkboxIndicacaoValida = this.validators.get('indicacaoAceita')!.value
    
    if(!this.checkboxIndicacaoValida){
      this.validators.get('motivoArquivamento')?.setValidators(Validators.required);
      this.validators.get('indicacaoAceita')?.clearValidators();
  }
    else{
      this.validators.get('indicacaoAceita')?.setValidators(Validators.required);
      this.validators.get('motivoArquivamento')?.clearValidators();
      this.validators.get('motivoArquivamento')?.setValue(null);
    }

    this.validators.get('indicacaoAceita')?.updateValueAndValidity();
    this.validators.get('motivoArquivamento')?.updateValueAndValidity();
    
    this.validators.get('indicacaoAceita')!.valueChanges.subscribe(value => {
      this.checkboxIndicacaoValida = value;
      if(!this.checkboxIndicacaoValida){
        this.validators.get('motivoArquivamento')?.setValidators(Validators.required);
        this.validators.get('indicacaoAceita')?.clearValidators();
      } else{
        this.validators.get('indicacaoAceita')?.setValidators(Validators.required);
        this.validators.get('motivoArquivamento')?.clearValidators();
        this.validators.get('motivoArquivamento')?.setValue(null);
      }

      this.validators.get('indicacaoAceita')?.updateValueAndValidity();
      this.validators.get('motivoArquivamento')?.updateValueAndValidity();
    });

    this.validators.get('tipoDocumento')!.valueChanges.subscribe(() => {
      this.updateRegiaoValidator();
    });

    if(this.indicacaoCondutorSelecionado.habilitacao_UfDocumento != null){
      this.estadoSelecionado = this.indicacaoCondutorSelecionado.habilitacao_UfDocumento;
    }
  }

  updateRegiaoValidator() {
    const isInternacionalChecked = this.checkboxs.some(checkbox => checkbox.name === 'Internacional' && checkbox.isCheck);
    const regiaoControl = this.validators.get('regiao')!

    if (isInternacionalChecked) {
      regiaoControl.clearValidators();
      regiaoControl.setValue(null);
      regiaoControl.disable();
    } else {
      regiaoControl.setValidators([Validators.required, Validators.minLength(2)]);
      regiaoControl.enable();

    }
    regiaoControl.updateValueAndValidity();
  }

  public async confirmarIndicacaoCondutor (): Promise<void> {
    const indicacao = {
      indicacaoId: this.indicacaoCondutorSelecionado.indicacaoID,
      motivoId: 0,
      nomeIndicado: this.validators.get('nomeCondutor')?.value,
      numeroCnh: this.validators.get('numeroDocumento')?.value,
      tipoCnh: this.validators.get('tipoDocumento')?.value,
      uFCnh: this.validators.get('regiao')?.value
    }

    try{
      await this.indicacaoService.postAceitarIndicacaoCondutor(indicacao);
      this.toastr.success('Indicação confirmada.')
    }catch(error) {
      this.toastr.error('Erro ao confirmar a indicação.')
    }

    await this.obterListaAtualizadaIndicacaoCondutores();
  }

  public async arquivarIndicacaoCondutor (): Promise<void> {
    const indicacao = {
      indicacaoId: this.indicacaoCondutorSelecionado.indicacaoID,
      motivoId: this.validators.get('motivoArq')?.value,
      nomeIndicado: this.validators.get('nomeCondutor')?.value,
      numeroCnh: this.validators.get('numeroDocumento')?.value,
      tipoCnh: this.validators.get('tipoDocumento')?.value,
      uFCnh: this.validators.get('regiao')?.value
    }

    try {
      await this.indicacaoService.postArquivarIndicacaoCondutor(indicacao);
      this.toastr.success('Indicação arquivada.')
    } catch(error) {
      this.toastr.error('Error ao arquivar a indicação.')
    }

    await this.obterListaAtualizadaIndicacaoCondutores();
  }

  public async atualizarIndicacaoCondutor (): Promise<void> {
    var validaAlt = 0;
    const indicacao = {  
      indicacaoId: this.indicacaoCondutorSelecionado.id,
      emailCondutor: this.validators.get('email')?.value,
      celularCondutor: this.validators.get('telefone')?.value,
      nomeIndicado: this.validators.get('nomeCondutor')?.value,
      numeroCnh: this.validators.get('numeroDocumento')?.value,
      tipoCnh: this.validators.get('tipoDocumento')?.value,
      uFCnh: this.validators.get('regiao')?.value,
      motivoId: this.validators.get('motivoArquivamento')?.value,
      indicacaoAceita: this.validators.get('indicacaoAceita')?.value
    }
    debugger 
    console.log(indicacao);

    if (this.indicacaoCondutorSelecionado.nomeCondutor === indicacao.nomeIndicado 
      && this.indicacaoCondutorSelecionado.emailCondutor === indicacao.emailCondutor
      && this.indicacaoCondutorSelecionado.celularCondutor === indicacao.celularCondutor 
      && this.indicacaoCondutorSelecionado.habilitacao_Tipo === indicacao.tipoCnh
      && this.indicacaoCondutorSelecionado.habilitacao_NumeroDocumento === indicacao.numeroCnh 
      && this.indicacaoCondutorSelecionado.habilitacao_UfDocumento === indicacao.uFCnh
      && this.indicacaoCondutorSelecionado.indicacaoAceita === this.validators.get('indicacaoAceita')?.value 
      && this.indicacaoCondutorSelecionado.motivoArquivamentoId === this.validators.get('motivoArquivamento')?.value){
        this.toastr.info('Nenhuma informação foi alterada')
        validaAlt = 1
    }

    if (validaAlt == 1){
      this.route.navigate(['/indicacoes-arquivadas']);
    } else {
      try {
        await this.indicacaoService.postAtualizarIndicacaoCondutor(indicacao);
        this.toastr.success('Indicação atualizada.');
        this.route.navigate(['/indicacoes-arquivadas']);
      } catch(error) {
        this.toastr.error('Error ao atualizar a indicação.')
      }
    }
    
  }

  public recebendoValueCheckbox(checkboxValue: any){
    this.checkboxs.forEach(check => {
      check.value === checkboxValue ?
        check.isCheck = !check.isCheck :
        check.isCheck = false
    });
    const checkboxIsTrue = this.checkboxs.filter(x => x.isCheck == true)
    if(checkboxIsTrue.length == 1) {
      this.validators.patchValue({ tipoDocumento: checkboxIsTrue[0].value})
    } else {
      this.validators.patchValue({ tipoDocumento: null});
    }
  }

  public receberValueCheckboxFile(checkboxValue: any) : void {
    this.limparImagens();
     this.checkboxsFile.forEach(check => {
      check.value === checkboxValue ?  check.isCheck = !check.isCheck : ''

      const checkboxIsTrue = this.checkboxsFile.filter(x => x.isCheck == true)
      this.mostrarDoisArquivos = checkboxIsTrue.length > 1;

      setTimeout(() => {
        check.isCheck === true ?
        this.mostrarImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem))
          :
        this.removerImagemAmpliada(
          new Documento(check.value.base64, check.value.typeDocumento, check.value.tipoImagem));
      }, 500)
    });
  }

  public removerImagemAmpliada(arquivoVisualizacao: Documento): void {
    if (arquivoVisualizacao.tipoImagem === 2) {
      this.mostrarPdf2 = false;
      this.mostrarImagem2 = false;
      this.imagem2 = '';
    }
    else if (arquivoVisualizacao.tipoImagem === 3) {
      this.mostrarPdf3 = false;
      this.mostrarImagem3 = false;
      this.imagem3 = '';
    }
  }

  public mostrarImagemAmpliada(arquivoVisualizacao: Documento): void {
    if(arquivoVisualizacao.typeDocumento === "application/pdf") {
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = true;
        this.mostrarImagem2 = false;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = true;
        this.mostrarImagem3 = false;
        this.imagem3 = arquivoVisualizacao.base64;
      }
    }
    else {
      if( arquivoVisualizacao.tipoImagem === 2){
        this.mostrarPdf2 = false;
        this.mostrarImagem2 = true;
        this.imagem2 = arquivoVisualizacao.base64;
      }
      if(arquivoVisualizacao.tipoImagem === 3) {
        this.mostrarPdf3 = false;
        this.mostrarImagem3 = true;
        this.imagem3 = arquivoVisualizacao.base64;
      }
    }
  }

  public RotacionarImagem(imagemId: string) : void {
    const containerImagem = document.getElementById(imagemId);
    const divElementImagem = containerImagem?.getElementsByTagName('div');
    const img = divElementImagem?.item(0);
    this.positionImagem = this.positionImagem + 90;
    img!.style.transform = `rotate(${this.positionImagem}deg)`;
  }


  public disableBtnAceite(): boolean {
    const formGroup: FormGroup = this.validators as FormGroup;

    // console.log("--------")
  // Log the state of each control in the FormGroup
  // Object.keys(formGroup.controls).forEach(key => {
  //   const control = formGroup.controls[key];
  //   console.log(`${key} is valid:`, control.valid);
  // });
    return this.validators.valid
  }

  private async definirIndicacaoCondutorSelecionada() : Promise<void> {
    this.indicacaoCondutores = this.indicacaoCondutores.filter( x => x.statusProcesso !== 2 )
    this.indicacaoCondutorSelecionado = this.indicacaoCondutores[0];
  }

  private popularFormulario() {
    //console.log(this.indicacaoCondutorSelecionado)
    this.validators.patchValue({
      dataEntrada: this.formatDate(this.indicacaoCondutorSelecionado.dataEntrada),
      dataAnalise: this.formatDate(this.indicacaoCondutorSelecionado.dataAnalise),
      tipoDocumento: this.indicacaoCondutorSelecionado.habilitacao_Tipo,
      regiao: this.indicacaoCondutorSelecionado.habilitacao_UfDocumento,
      numeroDocumento: this.indicacaoCondutorSelecionado.habilitacao_NumeroDocumento,
      nomeCondutor: this.indicacaoCondutorSelecionado.nomeIndicado,
      email: this.indicacaoCondutorSelecionado.emailCondutor,
      telefone: this.indicacaoCondutorSelecionado.celularCondutor,
      numeroLote: this.indicacaoCondutorSelecionado.loteId,
      usuarioAvaliacao: this.indicacaoCondutorSelecionado.nomeUsuarioAvaliacao,
      confirmacaoIndicacaoCondutor: this.indicacaoCondutorSelecionado.indicacaoAceita ? true : false,
      indicacaoAceita: this.indicacaoCondutorSelecionado.indicacaoAceita ? true : false,
    })

    this.validators.get('motivoArquivamento')!.setValue(this.indicacaoCondutorSelecionado.motivoArquivamentoId);

    this.checkboxs.forEach(check => {
      if(check.value === this.indicacaoCondutorSelecionado.habilitacao_Tipo){
        this.validators.patchValue({ tipoDocumento: check.value});
        check.isCheck = true;
      }
    });
  }

  formatDate(isoString: string): string {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
    }

  private async obterImagens(): Promise<void> {
    this.checkboxsFile = []
    await this.obterImagemDocumento(this.indicacaoCondutorSelecionado.id,
      2).then( resp => {
        if(resp) {
          this.checkboxsFile.push({name: '2', isCheck: false, value: resp});
        }
      })
    await this.obterImagemDocumento(this.indicacaoCondutorSelecionado.id,
      3).then(resp => {
        if(resp) {
          this.checkboxsFile.push({name: '3', isCheck: false, value: resp});
        }
      });
      this.carregouImagens = true;
  }

  private async obterImagemDocumento(indicacaoId: number, type: TipoImagem): Promise<any> {
    try{
      const response = await this.indicacaoService.getImagensDeIndicacao(indicacaoId, type);
      const imagemDocumentoType = response.headers.get('Content-Type');
      let imagemDocumentoBase64 = Buffer.from(response.body, 'binary').toString('base64');
      if(imagemDocumentoType != 'application/pdf'){
        imagemDocumentoBase64 = `data:image/png;base64,${imagemDocumentoBase64}`
      }
      return new Documento(imagemDocumentoBase64, imagemDocumentoType, type)
    } catch(error) {
    }
  }

  private async obterListaAtualizadaIndicacaoCondutores(): Promise<void> {
    try{
      this.indicacaoCondutores = [];
      const responseApi = await this.indicacaoService.getProcessosDeValidacao(this.loteId);
      responseApi.listaIndicacoes.forEach((x: any) => {
        const lote = new IndicacaoCondutor(x)
        this.indicacaoCondutores.push(lote)
      })
    }catch(error){}
    await this.definirIndicacaoCondutorSelecionada();
    this.limparImagens();
    this.limparCheckbox();
    this.popularFormulario();
    await this.obterImagens();
  }

  private limparImagens(): void{
    this.mostrarPdf2 = false;
    this.mostrarImagem2 = false;
    this.imagem2 = "";

    this.mostrarPdf3 = false;
    this.mostrarImagem3 = false;
    this.imagem3 = "";
  }

  private limparCheckbox(): void {
    this.checkboxs = [];
    this.checkboxs = [
      { name: "CNH", isCheck: false, value: 2  },
      { name: "PGU", isCheck: false, value: 1 },
      { name: "Internacional", isCheck: false, value: 3}
    ];
  }

  private async obterMotivoArquivamento(): Promise<void> {
    try {
      const responseApi = await this.motivoArquivamentoService.getMotivoArquivamento();
      responseApi.listaMotivosArquivamento.forEach((x: any) => {
        const motivo = new MotivoArquivamento(x)
        this.motivosArquivamento.push(motivo)
      })
    }catch(error){

    }
  }

  private getEnumTipeDocumento(tipoDocumento: number): string {
    if(tipoDocumento == 1)
      return 'Documento identificador'

    if(tipoDocumento == 2)
      return 'CNH do infrator'

    if(tipoDocumento == 3)
      return 'Formulário Indicação'

    if(tipoDocumento == 4)
      return 'Documento de responsabilidade'

    return ''
  }

  abrirModal(): void {
    $('#exampleModal').modal('show'); 
  }
  
  public validarIndicacao (): void {
    const indicacaoAceitaInicial = this.indicacaoCondutorSelecionado.indicacaoAceita; 
    const indicacaoAceitaFinal = this.validators.get('indicacaoAceita')?.value;
    const motivoArquivamentoInicial = this.indicacaoCondutorSelecionado.motivoArquivamentoId; 
    const motivoArquivamentoFinal = this.validators.get('motivoArquivamento')?.value;
    const descricaoMtvArqInicial = this.motivosArquivamento.find(x => x.codigoMotivo == motivoArquivamentoInicial)?.descricaoMotivo;
    const descricaoMtvArqFinal = this.motivosArquivamento.find(x => x.codigoMotivo == motivoArquivamentoFinal)?.descricaoMotivo;
    
    if(indicacaoAceitaInicial == true){

      if(indicacaoAceitaFinal == false){
        this.textoConfirmacaoModal = 'Deseja alterar a INDICAÇÃO ACEITA para arquivada com motivo ' + descricaoMtvArqFinal + '?';
        this.abrirModal();
      } else {
        this.atualizarIndicacaoCondutor();
      }
    }

    if(indicacaoAceitaInicial == false){

      if(indicacaoAceitaFinal == true){
        this.textoConfirmacaoModal = 'Deseja alterar a indicação de arquivada com ' + descricaoMtvArqInicial + ' para INDICAÇÃO ACEITA?';
        this.abrirModal();
      }

      if(indicacaoAceitaFinal == false){
        if(motivoArquivamentoInicial != motivoArquivamentoFinal){
          this.textoConfirmacaoModal = 'Deseja alterar o motivo de arquivamento de ' + descricaoMtvArqInicial + ' para ' + descricaoMtvArqFinal + '?';
          this.abrirModal();
        } else {
          this.atualizarIndicacaoCondutor();
        }
      }
    }
  }

}
