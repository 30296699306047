export class IndicacoesArquivadas {
  nomeIndicado: string;
  recursoId: number;
  statusProcesso: number;
  autoInfracao: string;
  dataProtocolo: Date;
  dataAnalise: Date;
  nrProtocolo: string;
  lote: number;
  motivoArquivamento: string;
  userAvaliacaoNome: string;
  usuarioAvaliacaoId: number;
  documento: string;
  placa: string;
  tipoDocumento: number;
  telefone: number;
  email: string;
  dataTransmissao: Date;
  habilitacao_UfDocumento: string;
  habilitacao_NumeroDocumento: string;

  constructor(response: any) {
   
    this.nomeIndicado = response.nomeIndicado;
    this.recursoId = response.id;
    this.statusProcesso = response.statusProcesso;
    this.nrProtocolo = response.protocolo;
    this.dataProtocolo = response.dataCriacao;
    this.dataAnalise = response.dataAnalise;
    this.autoInfracao = response.ait;
    this.lote = response.loteId;
    this.motivoArquivamento = response.motivoArquivamento.motivo
    this.usuarioAvaliacaoId = response.usuarioAvaliacao;
    this.documento = response.DocInteressadoCpfCnpj;
    this.placa = response.placaVeiculo;
    this.tipoDocumento = response.docInteressadoTipoDocumento;
    this.telefone = response.celularCondutor;
    this.email = response.emailCondutor;
    this.userAvaliacaoNome = response.nomeUsuarioAvaliacao;
    this.dataTransmissao = response.dataTransmissao;
    this.habilitacao_UfDocumento = response.habilitacao_UfDocumento;
    this.habilitacao_NumeroDocumento = response.habilitacao_NumeroDocumento;
  }
}
